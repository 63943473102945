import Popup from "@/lib/Popup";
import AddCategoryModal from '@/components/modals/AddCategoryModal';

export const openAddCategoryModal = () => {
    const popup = new Popup({
        component: AddCategoryModal,
        props: {},
        params: {
            transition: 'scale',
            name: 'add-category-modal',
            width: '100%',
            height: '100%',
            classes: 'modal-popup__overlay'
        }
    });
    
    popup.show();
};

export default {openAddCategoryModal};
