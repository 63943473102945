<template>
    <div class='modal-popup' @click='resetAlert' @keyup.13='onAddClick'>
        <div class='mb-3 modal-popup__title'>
            Добавление категории
            
            <svg
                xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#2D4C70'
                class='bi bi-x-lg modal-popup__close'
                viewBox='0 0 16 16'
                @click='close'
            >
                <path
                    d='M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z'
                />
            </svg>
        </div>
        <input type='text' class='form-control mb-2' placeholder='Название' v-model='name' ref='input'>
        <ui-button class='mt-3 mb-2' @clicked='onAddClick'>Добавить</ui-button>
        <div v-if='alert.length' class='alert alert-danger' role='alert'>{{ alert }}</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        name: '',
        alert: '',
        canReset: false,
    }),
    components: {
        UiButton: () => import('@/components/ui/UiButton'),
    },
    computed: {
        ...mapGetters('firebase', ['categories']),
    },
    mounted() {
        this.$refs.input.focus();
    },
    methods: {
        ...mapActions('firebase', ['saveCategory']),
        resetAlert() {
            if (this.canReset) {
                this.alert = '';
                this.canReset = false;
            }
        },
        setAlert(message) {
            this.alert = message;
            setTimeout(() => {
                this.canReset = true;
            }, 250);
        },
        async onAddClick() {
            if (this.name === '') {
                this.setAlert('Необходимо ввести название');
                return;
            }
            
            const id = this.getNewId();
            const res = await this.saveCategory({ name: this.name, id });
            
            if (res.error)
                this.setAlert(`Ошибка: ${res.error.toString()}`);
            else
                this.close();
        },
        getNewId() {
            return this.categories.length + 1;
        },
        close() {
            this.$modal.hide('add-category-modal');
        },
    },
};
</script>
